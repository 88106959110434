<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

type Direction = 'up' | 'down' | 'left' | 'right'

type Props = {
  name: string
  dir?: Direction
  noMargin?: boolean
  clickable?: boolean
}

const props = defineProps<Props>()

const icon = computed(() => defineAsyncComponent(() => import(`../../assets/img/icons/${props.name}.svg?component`)))

const classes = computed(() => {
  let c = 'b-icon-icon b-icon-fill'

  if (props.dir) c += ` b-icon-${props.dir}`
  if (props.noMargin) c += ' no-margin'
  if (props.clickable) c += ' clickable'

  return c
})
</script>

<template>
  <span :class="classes">
    <Component :is="icon" />
  </span>
</template>

<style lang="scss" scoped>
.b-icon-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: visible;

  svg {
    display: inline-block;
    color: inherit;
    vertical-align: middle;
    fill: #9197a4;
    stroke: currentColor;
    width: 16px;
    height: 16px;
  }
}

.b-icon-large {
  svg {
    width: 100px;
    height: 100px;
  }
}

.b-icon-medium {
  svg {
    width: 20px;
    height: 20px;
  }
}

.b-icon-small {
  svg {
    width: 10px;
    height: 10px;
  }
}

.clickable {
  cursor: pointer;
}

.no-margin {
  margin: 0 !important;
}

.b-icon-fill {
  svg {
    fill: currentColor;
    stroke: none;
  }
}

.b-icon-up {
  transform: rotate(0deg);
}

.b-icon-right {
  transform: rotate(90deg);
}

.b-icon-down {
  transform: rotate(180deg);
}

.b-icon-left {
  transform: rotate(-90deg);
}
</style>
