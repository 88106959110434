<script setup>
import { computed, useSlots } from 'vue'
import { useI18n } from 'vue-i18n'
import hasAtLeastOneRenderingChild from '@/utils/hasAtLeastOneRenderingChild'
import BIcon from '@/components/partials/Icon.vue'
import LoadingSpinner from '@/components/partials/Loading/LoadingSpinner.vue'

const slots = useSlots()

const textColorOptions = [
  'default',
  'brand',
]

const typeOptions = [
  'secondary',
  'secondary-inverted',
  'ghost',
  'ghost-inverted',
  'text',
  'red',
  'white-circle',
]

const sizes = [
  'xs',
  'sm',
  'lg',
  'xl',
  'xxl'
]

const hoverStyles = [
  'inverted'
]

const props = defineProps({
  textColor: { type: String, default: null },
  type: { type: String, default: 'primary' },
  hoverStyle: { type: String, default: 'default' },
  size: { type: String, default: 'md' },

  // Icon mode
  icon: { type: String, default: null },

  // Behavior
  alignRight: Boolean,
  fluid: Boolean,
  disabled: Boolean,
  loading: Boolean,
})

const emit = defineEmits(['click'])

const iconOnly = computed(() => props.icon && !hasAtLeastOneRenderingChild(slots))

const settings = computed(() => {
  const type = typeOptions.includes(props.type) ? props.type : false
  const textColor = textColorOptions.includes(props.textColor) ? props.textColor : false
  const size = sizes.includes(props.size) ? props.size : false
  const hoverStyle = hoverStyles.includes(props.hoverStyle) ? props.hoverStyle : false

  return {
    type,
    textColor,
    size,
    hoverStyle,
  }
})

const { t } = useI18n()
</script>

<template>
  <div v-taptic="disabled ? 'error' : 'selection'"
    class="button"
    role="button"
    :class="{
      // Text color
      [`button--color-${settings.textColor}`]: settings.textColor,

      // Types
      [`button--type-${type}`]: settings.type,

      // Hover styles
      [`button--hover-${hoverStyle}`]: settings.hoverStyle,

      // Sizes
      [`button--size-${size}`]: settings.size,

      // icon
      ['button--icon-only']: iconOnly,

      // Behavior
      ['button--align-right']: alignRight,
      ['button--disabled']: disabled,
      ['button--fluid']: fluid,
    }"
    @click="disabled ? false : emit('click')"
  >
    <span class="button__content">
      <BIcon v-if="icon" :name="icon" />

      <slot v-if="!loading" />
      <template v-else>
        <LoadingSpinner />
        {{ t('terms.busy') }}
      </template>
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/variables/breakpoints.scss';
@import '@/assets/css/mixins/styling.scss';

.button {
  $self: &;

  // defaults
  display: flex;
  width: max-content;
  position: relative;
  user-select: none;
  cursor: pointer;

  &__content {
    @include gradient-button;
    border: 1px solid transparent;
    z-index: 2;
    display: flex;
    padding: 0.75rem 1.5rem;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    white-space: nowrap;
    font-weight: 700;
    color: #fff;
    width: 100%;
    transition: all 200ms cubic-bezier(0.485, 0.205, 0.550, 1.580), background 0s linear, color 0s linear; /* custom */
  }

  &:deep(.b-icon-icon) {
    width: 1rem;
    height: 1rem;
    overflow: visible;
  }

  // Styling of focus ring
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    border-radius: 50rem;
    pointer-events: none;
    background: $new-gradient-brand-main;
    opacity: 0.25;
    transform: translate(-50%, -50%) scale(0.65);
    transition: all 175ms cubic-bezier(0.485, 0.205, 0.550, 1.580), background 0s linear; /* custom */
  }

  // Animating of focus ring
  &:focus,
  &:active,
  &:hover {
    &::before {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  // transformations
  &--icon-only {
    &:deep(.b-icon-icon) {
      width: 1.2rem;
      height: 1.2rem;
    }

    #{ $self }__content {
      padding: 0.75rem;
    }
  }

  // Types
  &--type-text {
    #{ $self }__content {
      color: var(--color-neutral-primary);
      border-color: transparent;
      background: transparent;
    }

    &::before {
      background: transparent;
      border: 2px solid transparent;
      width: 100%;
      height: 100%;
      @include lg-down{
        width: 70%;
        height: 70%;
      }
      transform: translate(-50%, -50%) scale(0.925);
    }

    &:hover {
      &::before {
        border: 2px solid $new-color-neutral-primary;
      }
    }
  }

  &--type-white-circle {
    #{ $self }__content {
      color: var(--color-neutral-primary);
      border-color: transparent;
      background: transparent;
    }

    &::before {
      background: transparent;
      width: 70%;
      height: 70%;
      transform: translate(-50%, -50%) scale(0.925);
    }

    &:hover {
      &::before {
        background: #fff;
      }
    }
  }

  &--type-secondary {
    #{ $self }__content {
      color: var(--color-neutral-primary);
      border: 1px solid var(--color-neutral-quaternary);
      background: #fff;
    }

    &::before {
      background: $new-gradient-brand-main;
    }
  }

  &--type-secondary-inverted {
    #{ $self }__content {
      color: var(--color-neutral-primary);
      background: transparent;
      background: $new-color-neutral-eightenary;
      border-color: transparent;
    }

    &:hover {
      #{ $self }__content {
        background: #fff;
      }
    }

    &::before {
      background: $new-gradient-brand-main;
    }
  }

  &--type-ghost {
    #{ $self }__content {
      color: $new-color-brand-red;
      background: linear-gradient(#fff, #fff) padding-box,
        $new-gradient-brand-button border-box;
      border-radius: 50em;
      border: 2px solid transparent;
    }

    #{ $self }__text {
      @include gradient-text;
    }
  }

  &--type-ghost-inverted {
    #{ $self }__content {
      color: #fff;
      border: 2px solid #fff;
      background: transparent;
    }

    &::before {
      background: transparent;
      border: 2px solid transparent;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%) scale(0.925);
    }

    &:hover {
      &::before {
        border: 8px solid #fff;
      }
    }
  }

  &--type-red {
    #{ $self }__content {
      color: var(--color-error-text);
      border-color: var(--color-warning-error-border);
      background: var(--color-warning-error-background);
    }

    &::before {
      background: rgb(255, 191, 180);
    }
  }

  // Colors
  &--color-brand {
    #{ $self }__content {
      color: $new-color-brand-red;
    }

    &:hover {
      &::before {
        border: 2px solid $new-color-brand-red;
      }
    }
  }

  &--color-default {
    #{ $self }__content {
      color: $new-color-neutral-primary;
    }
  }

  // Hover types
  &--hover-inverted {
    &::before {
      background: #fff;
    }
  }

  // Sizes
  &--size-xs {
    &::before {
      width: calc(100% + 0.15rem);
      height: calc(100% + 0.15rem);
      transform: translate(-50%, -50%) scale(0.5);
    }

    &#{ $self }__content {
      font-size: 0.8rem;
      padding: 0.35rem 0.75rem;
    }

    &#{ $self }--icon-only {
      padding: 0.35rem;

      #{ $self }__content {
        padding: 0.5rem;
      }

      &:deep(.b-icon-icon) {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }

  &--size-sm {
    #{ $self }__content {
      padding: 0.5rem 1rem;
    }

    &#{ $self }--icon-only {
      #{ $self }__content {
        padding: 0.5rem;
      }
    }
  }

  // --size-md is the default

  &--size-lg {
    #{ $self }__content {
      font-size: 1.25rem;
    }

    &#{ $self }--icon-only {
      &:deep(.b-icon-icon) {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  &--size-xl {
    #{ $self }__content {
      font-size: 1.5rem;
    }

    &#{ $self }--icon-only {
      &:deep(.b-icon-icon),
      &:deep(.b-icon-icon svg) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  // Behavior

  &--align-right {
    margin-left: auto;
  }

  &--fluid {
    width: 100%;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
