<template>
  <div class="loading-spinner"
    :style="{
      height: `${size}px`,
      width: `${size}px`,
      borderWidth: `${border}px`,
    }"
  />
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    size: {
      type: Number,
      default: 20,
    },
    border: {
      type: Number,
      default: 2,
    },
  },
}
</script>

<style lang="scss">
$loader-color: #242e4a;
$loader-size: 20px;
$loader-height: 20px;
$loader-border-size: 2px;
$loader-gap: 12px;
$loader-animation-duration: 1s;

@import 'node_modules/spinthatshit/src/loaders';

.loading-spinner {
  @include loader02;
}
</style>
